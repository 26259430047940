const frenchTranslation = {
  translation: {
    Menu: 'Menu',
    Home: 'Home',
    'All Categories': 'Toutes les Catégories',
    'Made into Animations': 'Faits d\'animations',
    'Our Publishers': 'Nos éditeurs',
    More: 'Plus',
    'What are you looking for': 'Que recherchez-vous',
    Login: 'Entrer',
    Register: 'S\'enregistrer',
    'My profile': 'Mon profil',
    'Dashboard': 'Dashboard',
    'Create new Book': 'Créer un nouveau contenu',
    'My Books': 'Mes Contenus',
    'All Books I Bought': 'Mes Contenus que j\'ai Acheté',
    'Logout': 'Sortir',
    'Welcome to': 'Bienvenue à',
    'We have more than': 'On possède plus de',
    'contents in our store': 'Contenus dans notre base',
    'We love stories': 'On aime les histoires',
    'and we believe that you should be able to enjoy African stories whenever and wherever':
      'et on croit que tu devrais être capable de profiter des histoires et recits africains n\'importe quand et n\'importe où',
    'allow that freedom': 'accepte cette liberté',
    'Share your stories in your local languages and help conserve African local languages for future generations':
      'Partage avec nous des histoires dans ta langue locale et permet du coup de sauvegarder votre langue locale pour des generations futures',
    'Are you ready for a great adventure?': 'Es-tu prêt pour une merveilleuse aventure?',
    'Newly Released': 'Nouvellement Sorties',
    'Add To Cart': 'Ajouter à la carte',
    'Buy It Now': 'Acheter maintenant',
    Pay: 'Payer',
    'See All': 'Voir Tout',
    'Best Sellers': 'Meilleurs Ventes',
    'Coming Soon': 'Bientôt Disponible',
    Company: 'Compagnie',
    'About Us': 'A propos de nous',
    'Support': 'Support',
    Help: 'Aide',
    'Contact Us': 'Contactez-Nous',
    'Connect': 'Connecter',
    Privacy: 'Privé',
    'Terms of use': 'Termes d\'utilisation',
    'Login Page': 'Page d\'entrer',
    'Email': 'Email',
    'Password': 'Mot de passe',
    'Forget Password': 'Mot de passe oublié',
    'New to': 'Nouveau chez',
    'Create an Account': 'Créer un compte',
    'Firstname': 'Prénom',
    'Lastname': 'Nom',
    'Telephone': 'Téléphone',
    'Or':'Ou',
    'Have an Account': 'Possède déjà un compte',
    'Login Here': 'Entrer ici',
    'Create Audio Book': 'Créer du Contenu',
    'Book Name': 'Nom du Contenu',
    'Author\'s Name': 'Nom de l\'auteur',
    'Price Of Book': 'Prix du Contenu',
    Category: 'Categorie',
    'Book\'s Summary':  'Résumé du Contenu',
    'Publishing or Production Company': 'Maison de Publication',
    'Made Into Animation': 'Fait d\'animations',
    'Please Fill Out All Details Where You\'ll receive your payment':
      'Veuiller remplir tous Les details de votre moyen de paiement',
    'Create Book': 'Créer un Contenu',
    Edit: 'Modifier',
    Delete: 'Effacer',
    'All Books Created by this User': 'Tous les Contenus du même auteur',
    'Do You Want To Delete This Book': 'Voudrais-tu effacer ce contenu?',
    'Update Profile': 'Mettre à jour le profile',
    'Edit my profile': 'Modifier mon profile',
    'Address': 'Addresse',
    'Road': 'Rue',
    'Website': 'Site Web',
    'Tel': 'Tel',
    'Send Us A Message': 'Nous envoyer un message',
    'FullName': 'Nom&Prenom',
    'Subject': 'Sujet',
    'Message Body': 'Contenu du message',
    'Send Message': 'Envoyer  Message',
    'Content not Found': 'Contentue non trouvé',
    By: 'Par',
    Published: 'Publié',
    'This is a free book': 'Contenu offert',
    'Listen to it for free':  'Ecouter gratuitement',
    'has only published one book, will Probably Publish Other books,  So Stay Tuned':
      'a déjà publié un seul contenu,et va surement oublier d\'autres bientot,restez connecté',
    'Author Of This Book': 'Conservateur de contenu',
    'Also Wrote The following Books': ' A aussi écrit Les contenus suivants',
    Share: 'Partager',
    Report: 'Reporter',
    'Report Book': 'Reporter un Contenu',
    'Similar Books': 'Contenus Similaires',
    'If you click on Delete Button, This Book will be deleted permanently':
      'Si tu appuies le bouton effacer,ce contenu sera définitivement effacé',
    'Report this Book': 'Reporter ce contenu',
    'Be patient, When a book is reported will be deleted in one day after final check up of Haapa Store admininistration':
      'Be patient, When a book is reported will be deleted in one day after final check up of Haapa Store admininistration',
    'Update Where you\'ll receive your payment': 
      'Mettre à jour tes details de payement',
    'Update Book': 'Mettre à jour le contenu',
    'Books in my Cart': 'Contenu Dans ma Carte',
    'Total Amount': 'Montant total',
    'Do You Want To Delete This Cart': 'Voudrais-tu effacer cette carte choisi',
    'If you click on Delete Button, This Cart will be deleted permanently': 
      'Si tu appuies ce bouton effacer, cette carte sera définitivement effacée',

    'All Recent Books': 'Les Nouvelle Contenus',
    'HAAPA Publishers': 'HAAPA Publishers',
    'Show Password': 'Montre mot de passe',
    'Reset Password': 'Réinitialiser mot de passe',
    'Confirm Password': 'Confirme mot de passe',
    'Reset Your Password': 'Réinitialiser le mot de passe',
    Submit: 'Envoyer',
    'Return to Login': 'Retour à la Entrer',
    'Created by': 'Crée par',
    'Pay With MTN Mobile Money': 'Payer avec MTN Mobile Money',
    'Pay with MTN Momo': 'Payer avec MTN MoMo',
    'Click To Pay with MTN MoMo': 'Clique pour payer avec MTN MoMo',
    'Enter Your MTN MoMo Number': 'Entrez votre numero de MTN MoMo',
    'This Operation will take up to 2min to proceed your payment':
      'Cette opération prendra jusqua\'à 2 minutes pour procéder à votre paiement',
    'MTN MoMo Payment': 'Paiement de MTN MoMo',
    'Views': 'Vues',
    Map: 'Carte',


    'Rate this content': 'Evaluer ce contenu',
    'All Reviews': 'Toute les Avis',
    Video: 'Vidéo',
    'This content has not been reviewed by any user': 
      'Ce contenu n\'a été examiné par aucun utilisateur',
    'Be the first to review it': 'Soyez le premier à donner votre avis',
    'This content has its own video': 'Ce contenu a sa propre vidéo',
    'Please, Buy it and watch the video': 'Veuiller l\'acheter et regarder la vidéo',
    'Add Video': 'Ajouter une Vidéo',
    'Stop Audio Recording': 'Arreter L\'enregistrement Audio',
    'or record sound now': 'ou enregistrez le son maintenant',
    'or record video now': 'ou enregistrez une vidéo maintenant',
    'Stop Video Recording': 'Arreter L\'enregistrement vidéo',
    'Wait File is uploading': 'Attendre le téléchargement du fichier',
    'Click here to read it': 'Clique ici pour lire ça',
    'Accept our terms and conditions of using our platform': 
      'Accepter nos conditions d\'utilisateur de notre plateforme',
    'Update Video': 'Mettre à jour la Vidéo',
    'All Books Made Into Animations': 'Tout les contenus transformé en animation',
    'User reviews': 'Critiques d\'utilisateurs',
    'Your rating is': 'Votre note est',
    'stars': 'étoiles',
    'WRITE YOUR REVIEW': 'DONNE VOTRE AVIS',
    Cancel: 'Annuler',
    Animations: "Animations",
    Categories: "Catégories",
    "Search here": "Cherche ici",
    "Read Book": "Lire un livre",
    "Update Cover Image": "Mettre à jour l'image de couverture",
    "Update Audio": "Mettre à jour le son",
    Tags: "Mots clés",

    "Content Info":"Informations sur le contenu",
    "Content Name":"Nom du contenu",
    "Content Price":"Contenu Prix",
    "Content Category":"Catégorie de contenu",
    "Content Tags":"Balises de contenu",
    "Content Summary":"Résumé du contenu",
    "Content Owner":"Propriétaire du contenu",
    "Author Name":"Nom de l'auteur",
    "Content Medias":"Médias de contenu",
    "Book File":"Fichier livre",
    "Payment Information":"Informations de paiement",
    "Agreement With HAAPA":"Accord avec HAAPA",
    "Creating Content":"Création de contenu",
    'Create New Content': 'créer du nouveau contenu',
    'Total Views':'Vues totales',
    'Total Reviews':'Nombre total d\'avis',
    'Read more':'Lire la suite',
    'Add Review':'Ajouter un commentaire',
    'Write Your Review':'Ajouter un commentaire',
    "Delete Content":"Supprimer du contenu",
    "Edit Content":"Modifier le contenu",
    "Read book":"Lire un livre",
    "Listen audio":"Écouter le son",
    "Watch video":"Regarder la vidéo",
    "Authored By": "Rédigé par",
    "Published By":"Publié par",
    "Share via":"Partage via",

    "Next": "Au suivant",
    "Go to Next": "Aller au suivant",
    "Payment Methods": "Méthodes de Payement",
    "Choosen Contents": "Contenu Choisi",
    "In My Cart" : "dans mon Panier",
    "No Internet": "Pas d'Internet",
    "No content to buy in your Cart": "Aucun contenu à acheter dans votre panier",
    "Go Home": "Aller à la page d'accueil",
    "Total Amount to pay": "Montant Total à Payer",
    "Content Seller Profile": "Profil du vendeur de contenu",
    "Click to Pay with": "Cliquez pour payer avec",

    'Book': 'Livre',
    'Audio': 'Audio',
    'Read Here': 'Lire Ici',
    'Listen Here': 'Ecoute Maintenant',
    'Watch Here': 'Regardez Ici'
  },
};

export default frenchTranslation;
