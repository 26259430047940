import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import { css } from '@emotion/react';

const override = css`
  border-color: red;
`;

export default function Spinner({color="#f15921"}) {
    return (
        // <div className="lds-dual-ring"></div>
        <BeatLoader loading={true} size={15} color={color} css={override} />
    )
}
