import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import 'dotenv/config';

import './index.css';
import App from './App';

import { I18nextProvider } from 'react-i18next';
import i18n from './utils/translations';
import Spinner from './common/Spinner';

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

const FallBackLoading = (
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="d-flex flex-column justify-content-center align-items-center flex-nowrap min-vh-100">
          <Spinner />
        </div>
      </div>
    </div>
  </div>
);

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <React.Suspense fallback={FallBackLoading}>
      <App />
    </React.Suspense>
  </I18nextProvider>,
  document.querySelector('#root'),
);
