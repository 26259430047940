import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
// import {createHttpLink} from 'apollo-link-http'
import { createUploadLink } from 'apollo-upload-client';
import { ApolloProvider } from '@apollo/react-hooks';
import ReactNotifications from 'react-notifications-component';
import { AuthProvider } from './context/auth';
import AppRoutes from './AppRoutes';

import { BACKEND_GRAPHQL_API_URL } from './utils/constants';
import 'semantic-ui-css/semantic.min.css';
import './App.css';

const App = props => {
  /**
   * This httpLink will enable us to upload the image and audio
   * with the help of apollo-upload-client package
   */
  const httpLink = createUploadLink({
    uri: `${BACKEND_GRAPHQL_API_URL}/api/`,
    headers: { authorization: localStorage.getItem('token') },
  });

  const client = new ApolloClient({ link: httpLink, cache: new InMemoryCache() });

  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <Router>
          <div className="App">
            <ReactNotifications />
            <AppRoutes />
          </div>
        </Router>
      </AuthProvider>
    </ApolloProvider>
  );
};

export default App;
