import dotenv from 'dotenv';

dotenv.config();

export const BACKEND_GRAPHQL_API_URL=`${process.env.REACT_APP_BACK_END_URL }` 
export const PAYPAL_CLIENT_ID= `${process.env.REACT_APP_PAYPAL_CLIENT_ID }`

export const categoryOptions = [
  { key: 'Kids', text: 'Kids', value: 'Kids' },
  { key: 'Teens', text: 'Teens', value: 'Teens' },
  { key: 'Culture', text: 'Culture', value: 'Culture' },
  { key: 'Science & Technology', text: 'Science & Technology', value: 'Science & Technology' },
  { key: 'Sports', text: 'Sports', value: 'Sports' },
  { key: 'Biography', text: 'Biography', value: 'Biography' },
  { key: 'Mystery', text: 'Mystery', value: 'Mystery' },
  { key: 'Classics', text: 'Classics', value: 'Classics' },
  { key: 'Thrillers', text: 'Thrillers', value: 'Thrillers' },
  { key: 'Romance', text: 'Romance', value: 'Romance' },
  { key: 'Fiction', text: 'Fiction', value: 'Fiction' },
  { key: 'Non-Fiction', text: 'Non-Fiction', value: 'Non-Fiction' },
  { key: 'Business', text: 'Business', value: 'Business' },
  { key: 'Arts', text: 'Arts', value: 'Arts' },
  { key: 'Entertainment', text: 'Entertainment', value: 'Entertainment' },
  { key: 'Travel & Adventure', text: 'Travel & Adventure', value: 'Travel & Adventure' },
  { key: 'Religion & Spirituality', text: 'Religion & Spirituality', value: 'Religion & Spirituality' },
  { key: 'Drama & Poetry', text: 'Drama & Poetry', value: 'Drama & Poetry' },
  { key: 'Health & Fitness', text: 'Health & Fitness', value: 'Health & Fitness' },
];
