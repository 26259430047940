import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';

// utils functions
import { AuthRoute, PrivateRoute, AdminPrivateRoute } from './utils/AuthRoute';
import { AuthContext } from './context/auth';

const Signup = React.lazy(() => import('./pages/Signup'));
const Login = React.lazy(() => import('./pages/Login'));
const Home = React.lazy(() => import('./pages/Home'));
const SignupSuccess = React.lazy(() => import('./pages/SignupSuccess'));
const VerifyAccount = React.lazy(() => import('./pages/VerifyAccount'));
const ResetPassword = React.lazy(() => import('./pages/ResetPassword'));
const SetNewPassword = React.lazy(() => import('./pages/SetNewPassword'));
const AllResentBooks = React.lazy(() => import('./pages/AllResentBooks'));
const OneBook = React.lazy(() => import('./pages/SingleBook'));
const AllBooksInMyCart = React.lazy(() => import('./pages/AllBooksInMyCart'));
const Profile = React.lazy(() => import('./pages/Profile'));
const AllHaapaBooks = React.lazy(() => import('./pages/AllHaapaBooks'));
const AllBooksOfUser = React.lazy(() => import('./pages/AllBooksOfUser'));
const ReportedBook = React.lazy(() => import('./pages/ReportedBooks'));
const PayOneBook = React.lazy(() => import('./components/payments/PayOneBook'));
const UserPaidBooks = React.lazy(() => import('./pages/UserPaidBooks'));
const AdminPayment = React.lazy(() => import('./components/payments/AdminPayment'));
const OverallPayment = React.lazy(() => import('./components/payments/OverallPayment'));
const SoldBook = React.lazy(() => import('./components/payments/SoldBook'));
const AllCategory = React.lazy(() => import('./pages/AllCategory'));
const AllAuthors = React.lazy(() => import('./pages/AllAuthors'));
const MadeIntoAnimation = React.lazy(() => import('./pages/MadeIntoAnimation'));
const TermsConditions = React.lazy(() => import('./pages/footer/TermsConditions'));
const ContactUs = React.lazy(() => import('./pages/footer/ContactUs'));
const Faq = React.lazy(() => import('./pages/footer/Faq'));
const Help = React.lazy(() => import('./pages/footer/Help'));
const AboutUs = React.lazy(() => import('./pages/footer/AboutUs'));
const Privacy = React.lazy(() => import('./pages/footer/Privacy'));
const AllContactUs = React.lazy(() => import('./pages/AllContactUs'));
const UserNotification = React.lazy(() => import('./pages/UserNotification'));
const AllNotificationsSent = React.lazy(() => import('./pages/AllNotificationsSent'));
const PageNotFound = React.lazy(() => import('./pages/Page404/PageNotFound'));
const SlidersManagement = React.lazy(() => import('./pages/Dashboard/Sliders/SlidersManagement'));
const LastFooter = React.lazy(() => import('./components/LastFooter'));
const AppDashboardRoutes = React.lazy(() => import('./AppDashboardRoutes'));
const CreateNewBook = React.lazy(() => import('./pages/CreateNewBook'));
const UpdateBook = React.lazy(() => import('./pages/UpdateBook'));

function AppRoutes() {
  //eslint-disable-next-line
  const { user, logout } = useContext(AuthContext);

  return (
    <div>
      <Switch>
        <Route exact path="/" component={Home} />
        <AuthRoute exact path="/auth/signup" component={Signup} />
        <Route exact path="/auth/signupSuccess" component={SignupSuccess} />
        <AuthRoute exact path="/auth/login" component={Login} />
        <Route exact path="/auth/verify/:token" component={VerifyAccount} />
        <Route exact path="/auth/reset-password" component={ResetPassword} />
        <Route exact path="/auth/set-new-password/:token" component={SetNewPassword} />
        <PrivateRoute exact path="/contents/create/new" component={CreateNewBook} />
        <PrivateRoute exact path="/contents/:slug/edit" component={UpdateBook} />
        <Route exact path="/contents/:slug" component={OneBook} />
        <Route exact path="/category/contents" component={AllCategory} />
        <Route exact path="/all/contents" component={AllResentBooks} />
        <PrivateRoute exact path="/contents/cart/:username" component={AllBooksInMyCart} />
        <Route exact path="/auth/profile/:username" component={Profile} />
        <PrivateRoute exact path="/dashboard/ui/:username" component={AppDashboardRoutes} />
        <PrivateRoute exact path="/dashboard/ui/all/contents" component={AllHaapaBooks} />
        <PrivateRoute exact path="/contents/all/:username" component={AllBooksOfUser} />
        <PrivateRoute exact path="/all/reported/contents" component={ReportedBook} />
        <PrivateRoute exact path="/contents/:slug/pay" component={PayOneBook} />
        <PrivateRoute exact path="/contents/sold/me" component={SoldBook} />
        <AdminPrivateRoute exact path="/users/payments" component={AdminPayment} />
        <AdminPrivateRoute exact path="/manage/sliders" component={SlidersManagement} />
        <AdminPrivateRoute exact path="/payments/overall" component={OverallPayment} />
        <PrivateRoute exact path="/users/paid-books" component={UserPaidBooks} />
        <Route exact path="/publishers" component={AllAuthors} />
        <Route exact path="/animation/contents" component={MadeIntoAnimation} />
        <Route exact path="/terms" component={TermsConditions} />
        <Route exact path="/contact-us" component={ContactUs} />
        <AdminPrivateRoute exact path="/contact_us/all/messages" component={AllContactUs} />
        <AdminPrivateRoute exact path="/all/notifications" component={AllNotificationsSent} />
        <Route exact path="/faq" component={Faq} />
        <Route exact path="/help" component={Help} />
        <Route exact path="/about-us" component={AboutUs} />
        <Route exact path="/privacy" component={Privacy} />
        <PrivateRoute exact path="/notification/:id/message" component={UserNotification} />
        <Route component={PageNotFound} />
      </Switch>
      <LastFooter />
    </div>
  );
}

export default AppRoutes;
