const kinyarwandaTranslation= {
  translation: {
    Menu: 'Ibirimo',
    Home: 'Ahabanza',
    'All Categories': 'Ibyiciro  Byose',
    'Made into Animations': 'Ibyakozwemo Animasiyo',
    'Our Publishers': 'Abanditsi Bacu',
    More: 'Ibindi',
    'What are you looking for': 'Ibyo urimo ushakisha',
    Login: 'Kwinjira',
    Register: 'Kwiyandikisha',
    'My profile': 'Umwirondoro Wange',
    'Dashboard': 'Dashboard',
    'Create new Book': 'Andika igihangano gishya',
    'My Books': 'Ibihangano Byange',
    'All Books I Bought': 'Ibihangano Byose Nishyuye',
    'Logout': 'Sohokamo',
    'Welcome to': 'Ikaze kuri',
    'We have more than': 'Dufite ibitabo by\'amajwi birega',
    'contents in our store': 'mu ububiko bwacu',
    'We love stories': 'Dukunda inkuru',
    'and we believe that you should be able to enjoy African stories whenever and wherever':
      ' kandi twizera neza ko ushobora kwishimira inkuru z\' Afurika aho waba uri hose mu gihe icyo aricyo cyose',
    'allow that freedom': 'ibigufashamo',
    'Share your stories in your local languages and help conserve African local languages for future generations':
      'Sangiza inkuru mu ururimi rwawe gakondo ufasha mu kubungabunga indimi gakondo z\'Afurika ku ahazaza',
    'Are you ready for a great adventure?': 'Witeguye kumva ibihangano byiza?',
    'Newly Released': 'Ibishyizweho Vuba Aha',
    'Add To Cart': 'Mu ububiko',
    'Buy It Now': 'Kigure Nonaha',
    Pay: 'Ishyura',
    'See All': 'Bona Ibindi',
    'Best Sellers': 'Ibyagurishijwe Cyane',
    'Coming Soon': 'Ibihangano Biri Hafi Gusohoka',
    Company: 'Kompanyi',
    'About Us': 'Abo Turibo',
    'Support': 'Ubufasha',
    Help: 'Ubufasha',
    'Contact Us': 'Duhamagare',
    'Connect': 'Dusange',
    Privacy: 'Privacy',
    'Terms of use': 'Amategeko y\'ikoreshwa',
    'Login Page': 'Ipaji yo kwinjiriraho',
    'Email': 'Imeli',
    'Password': 'Ijambo Banga',
    'Forget Password': 'Wibagiwe Ijambo Banga',
    'New to': 'Uri mushya kuri',
    'Create an Account': 'Funguza konti',
    'Firstname': 'Izina Ribanza',
    'Lastname': 'Izina Rikurikira',
    'Telephone': 'Telefoni',
    'Or':'Cyangwa',
    'Have an Account': 'Ufite konti',
    'Login Here': 'Injira aha',
    'Create Audio Book': 'Andika Igihangano',
    'Book Name': 'Izina ry\'igihangano',
    'Author\'s Name': 'Umwanditsi w\'igihangano',
    'Price Of Book': 'Igiciro k\'igihangano',
    Category: 'Ikiciro',
    'Book\'s Summary':  'Inshamake y\'igihangano',
    'Publishing or Production Company': 'Kompanyi yagisohoye',
    'Made Into Animation': 'Cyakozwemo Animasiyo',
    'Please Fill Out All Details Where You\'ll receive your payment':
      'Uzuza imyirondoro yose ijyanye naho uzishyurirwa',
    'Create Book': 'Bika igihangano',
    Edit: 'Hindura',
    Delete: 'Siba',
    'All Books Created by this User': 'Ibihangano byose by\'umwaditsi',
    'Do You Want To Delete This Book': 'Urashaka gusiba iki gihangano',
    'Update Profile': 'Hindura Umwirondoro',
    'Edit MY Profile': 'Hindura Umwirondoro',
    'Address': 'Aho tubarizwa',
    'Road': 'Umuhanda',
    'Website': 'Urubuga',
    'Tel': 'Telefoni',
    'Send Us A Message': 'Twoherereze Ubutumwa',
    'FullName': 'Amazina yawe',
    'Subject': 'Subject',
    'Message Body': 'Ubutumwa',
    'Send Message': 'Ohereza Ubutumwa',
    'Content not Found': 'Content not Found',
    By: 'Na',
    Published: 'Cyashyizweho',
    'This is a free book': 'Iki gihangano ni ubuntu',
    'Listen to it for free':  'Cyumve ntakiguzi',
    'has only published one book, will Probably Publish Other books,  So Stay Tuned':
      'yanditse igihangano kimwe gusa, komeza umukurikire azandika ibindi',
    'Author Of This Book': 'Umwanditsi',
    'Also Wrote The following Books': 'Yanditse kandi ibihangano bikurikira',
    Share: 'Sangiza',
    Report: 'Raporo',
    'Report Book': 'Tanga Raporo',
    'Similar Books': 'Ibindi bihangano bijya gusa',
    'If you click on Delete Button, This Book will be deleted permanently':
      'Nukanda buto yo Gusiba, igihangano kirahita gisibwa burundu',
    'Report this Book': 'Tanga Raporo y\'iki gihangano',
    'Be patient, When a book is reported will be deleted in one day after final check up of Haapa Store admininistration':
      'Itondere ibi, Mugihe uduhaye raporo y\'igihangano gisibwa burundu mububiko bwa Haapa Store nyuma y\'isuzumwa ry\'ubuyobozi',
    'Update Where you\'ll receive your payment': 
      'Hindura aho uzakirira ubwishyu',
    'Update Book': 'Hindura igihangano',
    'Books in my Cart': 'Ibihangano biri mu ububiko bwange',
    'Total Amount': 'Amafaranga Yose',
    'Do You Want To Delete This Cart': 'Urashaka gusiba ikiri mu ububiko bwawe',
    'If you click on Delete Button, This Cart will be deleted permanently': 
      'Nukanda buto yo Gusiba, igihangano kirava burundu mu ubibiko bwawe',

    'All Recent Books': 'Ibihangano bya vuba',
    'HAAPA Publishers': 'Abanditsi ba HAAPA',
    'Show Password': 'Garagaza Ijambo Banga',
    'Reset Password': 'Hindura Ijambo Banga',
    'Confirm Password': 'Emeza Ijambo Banga',
    'Reset Your Password': 'Hindura Ijambo Banga Ryawe',
    Submit: 'Ohereza',
    'Return to Login': 'Subira Kwinjira',
    'Created by': 'Yakozwe na',
    'Pay With MTN Mobile Money': 'Ishyura na MTN Mobile Money',
    'Pay with MTN Momo': 'Ishyura na MTN Momo',
    'Click To Pay with MTN MoMo': 'Kanda wishyure na MTN MoMo',
    'Enter Your MTN MoMo Number': 'Uzuza nomero yawe ya MTN MoMo',
    'This Operation will take up to 2min to proceed your payment':
      'Iki gikorwa kimara hafi iminota ibiri kugira kirangire',
    'MTN MoMo Payment': 'Kwishyura na MTN MoMo',
    'Views': 'Bagisuye',

    Map: 'Ikarita',
    'Rate this content': 'Rate this content',
    'All Reviews': 'All Reviews',
    Video: 'Amashusho',
    'This content has not been reviewed by any user': 'Ntawe urasiga ubutumwa aha',
    'Be the first to review it': 'Ba uwambere gusiga ubutumwa',
    'This content has its own video': 'Iki gihangano gifite animasiyo yacyo',
    'Please, Buy it and watch the video': 'Kigure ubone kureba iyo animasiyo',
    'All Books Made Into Animations': 'Ibihangano byakozwemo animasiyo',
    'Add Video': 'Ongeraho Amashusho',
    'Stop Audio Recording': 'Hagarika kwifata amajwi',
    'or record sound now': 'Ifate amajwi nonaha',
    'or record video now': 'Ifate amashusho nonaha',
    'Stop Video Recording': 'Hagarika kwifata amashusho',
    'Wait File is uploading': 'Wait File is uploading',
    'Click here to read it': 'Kanda aha uyasome',
    'Accept our terms and conditions of using our platform': 
      'Emeza amategeko n\'amabwiriza mugukoresha HAAPA Store',
    'Update Video': 'Hindura Amashusho',
    'User reviews': 'Abagize icyo bavuga',
    'Your rating is': 'Your rating is',
    'stars': 'stars',
    'WRITE YOUR REVIEW': 'WRITE YOUR REVIEW',
    Cancel: 'Hagarika',
    Animations: "Animasiyo",
    Categories: "Ibyiciro",
    "Search here": "Shakisha aha",
    "Read Book": "Soma iki gitabo",
    "Update Cover Image": "Hindura ifoto",
    "Update Audio": "Kuvugurura amajwi",

    "Content Info":"Amakuru y'ibanze",
    "Content Name":"Izina ry'igihangano",
    "Content Price":"Igiciro cy'igihangano",
    "Content Category":"Ikiciro",
    "Content Tags":"Contenti Tagi",
    "Content Summary":"Incamake y'igihangano",
    "Content Owner":"Nyir'igihangano",
    "Author Name":"Amazina ya nyir'igihangano",
    "Content Medias":"Ubwoko bw'igihangano",
    "Book File":"Igitabo",
    "Payment Information":"Amakuru yaho ugo kwishyurirwa",
    "Agreement With HAAPA":"Amasezerano na HAAPA",
    "Creating Content":"Tegereza gato",
    'Create New Content': 'Ahoreza igihangano',
    'Total Views':'Umubare rusange wa bayisuye',
    'Total Reviews':'Umubare rusange waba Reviewinze',
    'Read more':'Soma byinshi birushijeho',
    'Add Review':'Shyiraho igitekerezo',
    'Write Your Review':'Shyiraho igitekerezo',
    "Delete Content":"Siba ibirimo",
    "Edit Content":"Hindura ibirimo",
    "Read book":'Soma igitabo',
    "Watch video":'Reba videwo',
    "Listen audio":'Umva amajwi',
    "Authored By": "Yanditswe na",
    "Published By":"Yasohowe na",
    "Share via":"yitangarize abandi uciye kuri",

    "Next": "Ibikurikira",
    "Go to Next": "Reba Ibikurikira",
    "Payment Methods": "Uburyo bwo Kwishura",
    "Choosen Contents": "Choosen Contents",
    "In My Cart" : "in My Cart",
    "No Internet": "Nta Interineti",
    "No content to buy in your Cart": "No content to buy in your Cart",
    "Go Home": "Subira Ahabanza",
    "Total Amount to pay": "Amafaranga Yose yo Kwishyurara",
    "Content Seller Profile": "Umwirondoro wabagurisha",
    "Click to Pay with": "Kanda Kwishura na",

    'Book': 'Igitabo',
    'Audio': 'Ijwi',
    'Read Here': 'Soma Hano',
    'Listen Here': 'Umva Hano',
    'Watch Here': 'Reba Hano',
  },

};

export default kinyarwandaTranslation;
