const swahiliTranslation= {
    translation: {

      Menu: 'Menyu',
      Home: 'Ukurasa wa nyumbani',
      'All Categories': 'Aina zote',
      'Made into Animations': 'Kilifanywa Ndani ya Uhuishaji',
      'Our Publishers': 'Waandishi Wetu',
      More: 'Zaidi',
      'What are you looking for': 'Unatafuta nini',
      Login: 'Ingia',
      Register: 'Jiandikishe',
      'My profile': 'Wasifu Wangu',
      'Dashboard': 'Dashibodi',
      'Create new Book': 'Unda yaliyomo mpya',
      'My Books': 'Yaliyomo yangu',
      'All Books I Bought': 'Yote yaliyomo nilinunua',
      'Logout': 'Logi',
      'Welcome to': 'Karibuni kwenye',
      'We have more than': 'Tuna zaidi',
      'contents in our store': 'Yaliyomo katika duka letu',
      'We love stories': 'Tunapenda hadithi',
      'and we believe that you should be able to enjoy African stories whenever and wherever':
        'na tunaamini kwamba unapaswa kufurahiya hadithi za Kiafrika wakati wowote na mahali popote',
      'allow that freedom': 'ruhusu uhuru huo',
      'Share your stories in your local languages and help conserve African local languages for future generations':
        'Shiriki hadithi zako katika lugha zako za ndani na usaidie kuhifadhi lugha za kiafrika kwa kizazi kijacho',
      'Are you ready for a great adventure?': 'Uko tayari tusafiri pamwe?',
      'Newly Released': 'Iliyotolewa Upya',
      'Add To Cart': 'Add To Cart',
      'Buy It Now': 'Nunuacho sasa',
      Pay: 'Lipa',
      'See All': 'Ona Vyote',
      'Best Sellers': 'Wauzaji Bora',
      'Coming Soon': 'Kuja hivi Karibuni',
      Company: 'Kampuni',
      'About Us': 'Kuhusu Sisi',
      'Support': 'Msaada',
      Help: 'Msaada',
      'Contact Us': 'Wasiliana Nasi',
      'Connect': 'Unganisha',
      Privacy: 'Faragha',
      'Terms of use': 'Masharti ya matumizi',
      'Login Page': 'Ukurasa wa Kuingia',
      'Email': 'Barua pepe',
      'Password': 'Nywila',
      'Forget Password': 'Unasahau nywila',
      'New to': 'Mpya kwa',
      'Create an Account': 'Unda akaunti mpya',
      'Firstname': 'Jina la kwanza',
      'Lastname': 'Jina la familia',
      'Telephone': 'Simu',
      'Or':'au',
      'Have an Account': 'Una Akaunti',
      'Login Here': 'Ingia Hapa',
      'Create Audio Book': 'Tengeneza yaliyomo',
      'Book Name': 'Jina la yaliyomo',
      'Author\'s Name': 'Jina la Mwandishi',
      'Price Of Book': 'Bei ya yaliyomo',
      Category: 'Aina',
      'Book\'s Summary':  'Muhtasari wa yaliyomo',
      'Publishing or Production Company': 'Kampuni Kilichochapishiwapo',
      'Made Into Animation': 'Kilifanywa kwa Uhuishaji',
      'Please Fill Out All Details Where You\'ll receive your payment':
        'Tafadhali jaza maelezo yote ambapo utapokea malipo yako',
      'Create Book': 'Tengeneza yaliyomo',
      Edit: 'Hariri',
      Delete: 'Futa ',
      'All Books Created by this User': 'Yaliyomo yote yaliyoundwa na mtumiaji huyu',
      'Update Profile': 'Sasisha Wasifu',
      'Edit my profile': 'Hariri maelezo yako Mafupi',
      'Address': 'Anuani',
      'Road': 'Barabara',
      'Website': 'Tovuti',
      'Tel': 'Simu',
      'Send Us A Message': 'Tutumie Ujumbe',
      'FullName': 'Jina kamili',
      'Subject': 'Mada',
      'Message Body': 'Mwili wa Ujumbe',
      'Send Message': 'Tuma Ujumbe',
      'Content not Found': 'Yaliyomo Hayapatikakani',
      By: 'Na',
      Published: 'Iliyochapishwa',
      'This is a free book': 'Haya ni yaliyomo bure',
      'Listen to it for free':  'Yasikilize kwa bure',
      'has only published one book, will Probably Publish Other books,  So Stay Tuned':
        'amechapisha tu yaliyomo,labda atachapisha yaliyomo mengine',
      'Author Of This Book': 'Mwandishi',
      'Also Wrote The following Books': 'Pia aliandika yaliyomo yafuatayo',
      Share: 'Shiriki',
      Report: 'Ripoti',
      'Report Book': 'Ripoti yaliyomo',
      'Similar Books': 'Yaliyomo sawa',
      'Do You Want To Delete This Book':'Unataka kufuta yaliyomo haya',
      'If you click on Delete Button, This Book will be deleted permanently':
        'Ukibonyeza kitufe cha kufuta, yaliyomo haya yatafutwa kabisa',
      'Report this Book': 'Ripoti yaliyomo haya',
      'Be patient, When a book is reported will be deleted in one day after final check up of Haapa Store admininistration':
        'Kuwa na subira, yaliyomo yatakaporipotiwa yatafutwa baada ya ukaguzi wa misho wa usimamizi wa duka la HAAPA',
  


      'Update Where you\'ll receive your payment': 
        'Sasisha Ambapo utapokea malipo yako',
      'Update Book': 'Sasisha yaliyomo',
      'Books in my Cart': 'Yaliyomo kwenye Kikapu changu',
      'Total Amount': 'Jumla',
      'Do You Want To Delete This Cart': 'Je! Unataka Kufuta Maudhui haya',
      'If you click on Delete Button, This Cart will be deleted permanently': 
        'If you click on Delete Button, This Cart will be deleted permanently',

      'All Recent Books': 'Yote yaliyomo hivi karibuni',
      'HAAPA Publishers': 'Waandishi wa HAAPA',
      'Show Password': 'Onyesha nywila',
      'Reset Password': 'Weka upya nenosiri',
      'Confirm Password': 'Thibitisha nenosiri',
      'Reset Your Password': 'Weka upya nywila yako',
      Submit: 'Wasilisha',
      'Return to Login': 'Kurudi kuingia',
      'Created by': 'Imetengenezwa na',
      'Pay With MTN Mobile Money': 'Lipa kwa pesa ya rununu ya MTN',
      'Pay with MTN Momo': 'Lipa kwa pesa ya rununu ya MTN',
      'Click To Pay with MTN MoMo': 'Bonyeza kulipa na MTN Momo',
      'Enter Your MTN MoMo Number': 'Ingiza nambari yako ya Momo ya MTN',
      'This Operation will take up to 2min to proceed your payment':
        'Operesheni hii itachukua hadi dakika 2 kuendelea na malipo yako',
      'MTN MoMo Payment': 'Malipo ya Momo ya MTN',
      'Views': 'Maoni',
      Map: 'Ramani',



    'Rate this content': 'Kiwango maudhui haya',
    'All Reviews': 'Hakiki Zote',
    Video: 'Video',
    'This content has not been reviewed by any user': 
      'Maudhui haya hayajakaguliwa na mtumiaji',
    'Be the first to review it': 'Ku wa kwanza kuipia',
    'This content has its own video': 'Maudhui haya yana video yake mwenyewe',
    'Please, Buy it and watch the video': 'Tafadhali, nunua na utazame video',
    'Add Video': 'Ongeza Video',
    'Stop Audio Recording': 'Acha kurekodi sauti',
    'or record sound now': 'au rekodi sauti sasa',
    'or record video now': 'au rekodi video sasa',
    'Stop Video Recording': 'Acha kurekodi video',
    'Wait File is uploading': 'Subili faili inapakia',
    'Click here to read it': 'Bongeza hapa kuisoma',
    'Accept our terms and conditions of using our platform': 
      'Kubali sheria na masharti yetu ya kutumia juwaa letu',
    'Update Video': 'Sasisha Video',
    'All Books Made Into Animations': 'Yaliyomo yote yamefanywa kuwa uhuishaji',
    'User reviews': 'Mapitio ya watumiaji',
    'Your rating is': 'Ukadiriaji wako ni',
    'stars': 'nyota',
    'WRITE YOUR REVIEW': 'ANDIKA HAKIKI YAKO',
    Cancel: 'Ghairi',
    Animations: "Uhuishaji",
    Categories: "Aina",
    "Search here": "Tafuta hapa",
    "Read Book": "Soma kitabu hiki",
    "Update Cover Image": "Sasisha Picha ya Jalada",
    "Update Audio": "Sasisha Sauti",

    "Content Info":"Maelezo ya Yaliyomo",
    "Content Name":"Jina la Maudhui",
    "Content Price":"Bei ya Maudhui",
    "Content Category":"Jamii ya Maudhui",
    "Content Tags":"Lebo za Yaliyomo",
    "Content Summary":"Muhtasari wa Yaliyomo",
    "Content Owner":"Mmiliki wa Maudhui",
    "Author Name":"Jina la Mwandishi",
    "Content Medias":"Medias za Maudhui",
    "Book File":"Kitabu cha Kitabu",
    "Payment Information":"habari ya malipo",
    "Agreement With HAAPA":"Makubaliano na HAAPA",
    "Creating Content":"Kuunda Yaliyomo",
    'Create New Content': 'unda yaliyomo mpya',
    'Total Views':'Jumla ya Mionekano',
    'Total Reviews':'Mapitio ya Jumla',
    'Read more':'Soma zaidi',
    'Add Review':'Ongeza hakiki',
    'Write Your Review':'Ongeza hakiki',
    "Delete Content":"Futa yaliyomo",
    "Edit Content":"Hariri yaliyomo",
    "Read book":"Kusoma kitabu",
    "Listen audio":"Sikiza sauti",
    "Watch video":"Tazama video",
    "Authored By": "Imeandikwa Na",
    "Published By":"Imechapishwa na",
    "Share via":"Shiriki kupitia",

    "Next": "Ifuatayo",
    "Go to Next": "Nenda kwa Ijayo",
    "Payment Methods": "Njia za Malipo",
    "Choosen Contents": "Yaliyochaguliwa",
    "In My Cart" : "in My Cart",
    "No Internet": "Hakuna Mtandao",
    "No content to buy in your Cart": "Hakuna maudhui ya kununua kwenye Kikapu chako",
    "Go Home": "Nenda Ukurasa wa Nyumbani",
    "Total Amount to pay": "Jumla ya Kiasi cha kulipa",
    "Content Seller Profile": "Profaili ya Muuzaji wa Maudhui",
    "Click to Pay with": "Bofya ili Kulipa na",

    'Book': 'Kitabu',
    'Audio': 'Sauti',
    'Read Here': 'Soma Hapa',
    'Listen Here': 'Sikiliza Hapa',
    'Watch Here': 'Tazama Hapa'
    },
  };

  export default swahiliTranslation;
