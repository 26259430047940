const englishTranslation = {
  translation: {
    Menu: 'Menu',
    Home: 'Home',
    'All Categories': 'Categories',
    'Made into Animations': 'Made into animations',
    'Our Publishers': 'Publishers',
    More: 'More',
    'What are you looking for': 'What are you looking for',
    Login: 'Login',
    Register: 'Register',
    'My profile': 'My profile',
    'Dashboard': 'Dashboard',
    'Create new Book': 'Create new content',
    'My Books': 'My Contents',
    'All Books I Bought': 'Contents I bought',
    'Logout': 'Logout',
    'Welcome to': 'Welcome to',
    'We have more than': 'We have more than',
    'contents in our store': 'contents in our store',
    'We love stories': 'We love stories',
    'and we believe that you should be able to enjoy African stories whenever and wherever':
      'and we believe that you should be able to enjoy African stories whenever and wherever',
    'allow that freedom': 'allow that freedom',
    'Share your stories in your local languages and help conserve African local languages for future generations':
      'Share your stories in your local languages and help conserve African local languages for future generations',
    'Are you ready for a great adventure?': 'Are you ready for a great adventure?',
    'Newly Released': 'Newly Released Contents',
    'Add To Cart': 'CART',
    'Buy It Now': 'PAY',
    Pay: 'Pay',
    'See All': 'See All',
    'Best Sellers': 'Most Sold Contents',
    'Coming Soon': 'Content Coming Soon',
    Company: 'Company',
    'About Us': 'About us',
    'Support': 'Support',
    Help: 'Help',
    'Contact Us': 'Contact us',
    'Connect': 'Connect',
    Privacy: 'Privacy',
    'Terms of use': 'Terms of use',
    'Login Page': 'Login Page',
    'Email': 'Email',
    'Password': 'Password',
    'Forget Password': 'Forget Password',
    'New to': 'New to',
    'Create an Account': 'Create an Account',
    'Firstname': 'Firstname',
    'Lastname': 'Lastname',
    'Telephone': 'Telephone',
    'Or':'Or',
    'Have an Account': 'Have an Account',
    'Login Here': 'Login Here',
    'Create Audio Book': 'Create New Content',
    'Book Name': 'Content Name',
    'Author\'s Name': 'Author\'s Name',
    'Price Of Book': 'Price Of Content',
    Category: 'Category',
    'Book\'s Summary':  'Content\'s Summary',
    'Publishing or Production Company': 'Publishing or Production Company',
    'Made Into Animation': 'Made Into Animation',
    'Please Fill Out All Details Where You\'ll receive your payment':
      'Please Fill Out All Details Where You\'ll receive your payment',
    'Create Book': 'Create New Content',
    Edit: 'Edit',
    Delete: 'Delete',
    'All Books Created by this User': 'All contents created by this user',
    'Do You Want To Delete This Book': 'Do you want to delete this content',
    'Update Profile': 'Update Profile',
    'Edit my profile': 'Edit Profile',
    'Address': 'Address',
    'Road': 'Road',
    'Website': 'Website',
    'Tel': 'Tel',
    'Send Us A Message': 'Send Us A Message',
    'FullName': 'FullName',
    'Subject': 'Subject',
    'Message Body': 'Message Body',
    'Send Message': 'Send Message',
    'Content not Found': 'Content not Found',
    By: 'By',
    Published: 'Published',
    'This is a free book': 'This is a free content',
    'Listen to it for free':  'Listen to it for free',
    'has only published one book, will Probably Publish Other books,  So Stay Tuned':
      'has only published one content, will probably publish other books. So stay tuned',
    'Author Of This Book': 'Content Curator',
    'Also Wrote The following Books': 'Also Wrote The following Contents',
    Share: 'Share',
    Report: 'Report',
    'Report Book': 'Report Content',
    'Similar Books': 'Similar Contents',
    'If you click on Delete Button, This Book will be deleted permanently':
      'If you click on Delete Button, This content will be deleted permanently',
    'Report this Book': 'Report this content',
    'Be patient, When a book is reported will be deleted in one day after final check up of Haapa Store admininistration':
      'Attention! When a content is reported will be deleted in one day, after making the final checkup of Haapa Store admininistration',
    'Update Where you\'ll receive your payment': 
      'Update Where you\'ll receive your payment',
    'Update Book': 'Update Content',
    'Books " : "': 'Contents " : "',
    'Total Amount': 'Total Amount',
    'Do You Want To Delete This Cart': 'Do you want to delete this cart',
    'If you click on Delete Button, This Cart will be deleted permanently': 
      'If you click on delete button, This cart will be deleted permanently',

    'All Recent Books': 'Recently Contents',
    'HAAPA Publishers': 'HAAPA Publishers',
    'Show Password': 'Show Password',
    'Reset Password': 'Reset Password',
    'Confirm Password': 'Confirm Password',
    'Reset Your Password': 'Reset Your Password',
    Submit: 'Submit',
    'Return to Login': 'Return to Login',
    'Created by': 'Created by',
    'Pay With MTN Mobile Money': 'Pay With MTN Mobile Money',
    'Pay with MTN Momo': 'Pay with MTN Momo',
    'Click To Pay with MTN MoMo': 'Click To Pay with MTN MoMo',
    'Enter Your MTN MoMo Number': 'Enter Your MTN MoMo Number',
    'This Operation will take up to 2min to proceed your payment':
      'This Operation will take up to 2min to proceed your payment',
    'MTN MoMo Payment': 'MTN MoMo Payment',
    'Views': 'Views',
    Map: 'Map',



    'Rate this content': 'Rate this content',
    'All Reviews': 'All Reviews',
    Video: 'Video',
    'This content has not been reviewed by any user': 'This content has not been reviewed by any user',
    'Be the first to review it': 'Be the first to review it',
    'This content has its own video': 'This content has its own video',
    'Please, Buy it and watch the video': 'Please, Buy it and watch the video',
    'Add Video': 'Add Video',
    'Stop Audio Recording': 'Stop Audio Recording',
    'or record sound now': 'or record sound now',
    'or record video now': 'or record video now',
    'Stop Video Recording': 'Stop Video Recording',
    'Wait File is uploading': 'Wait File is uploading',
    'Click here to read it': 'Click here to read it',
    'Accept our terms and conditions of using our platform': 
      'Accept our terms and conditions of using our platform',
    'Update Video': 'Update Video',
    'All Books Made Into Animations': 'HAAPA Contents Made Into Animations',
    'User reviews': 'User reviews',
    'Your rating is': 'Your rating is',
    'stars': 'stars',
    'WRITE YOUR REVIEW': 'WRITE YOUR REVIEW',
    Cancel: 'Cancel',
    Animations: "Animations",
    Categories: "Categories",
    "Search here": "search here",
    "Read Book": "Read Book",
    "Update Cover Image": "Update Cover Image",
    "Update Audio": "Update Audio",
    Tags: "Tags",

    "Content Info":"Content Info",
    "Content Name":"Content Name",
    "Content Price":"Content Price",
    "Content Category":"Content Category",
    "Content Tags":"Content Tags",
    "Content Summary":"Content Summary",
    "Content Owner":"Content Owner",
    "Author Name":"Author Name",
    "Content Medias":"Content Medias",
    "Book File":"Book File",
    "Payment Information":"Payment Information",
    "Agreement With HAAPA":"Agreement With HAAPA",
    "Creating Content":"Creating Content",
    'Create New Content': 'Create New Content',

    'Total Views':'Total Views',
    'Total Reviews':'Total Reviews',
    'Read more':'Read more',
    'Add Review':'Add Review',
    'Write Your Review':'Write Your Review',
    "Delete Content":"Delete Content",
    "Edit Content":"Edit Content",
    "Read book":"Read book",
    "Listen audio":"Listen audio",
    "Watch video":"Watch video",
    "Authored By": "Authored By",
    "Published By":"Published By",
    "Share via":"Share via",
    
    "Next": "Next",
    "Go to Next": "Go to Next",
    "Payment Methods": "Payment Methods",
    "Choosen Contents": "Choosen Contents",
    "In My Cart" : "in My Cart",
    "No Internet": "No Internet",
    "No content to buy in your Cart": "No content to buy in your Cart",
    "Go Home": "Go Home",
    "Total Amount to pay": "Total Amount to pay",
    "Content Seller Profile": "Content Seller Profile",
    "Click to Pay with": "Click to Pay with",
    
    'Book': 'Book',
    'Audio': 'Audio',
    'Read Here': 'Read Here',
    'Listen Here': 'Listen Here',
    'Watch Here': 'Watch Here'
  },
};

export default englishTranslation;
