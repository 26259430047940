import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
// import {createHttpLink} from 'apollo-link-http'
import { createUploadLink } from 'apollo-upload-client';
import { ApolloProvider } from '@apollo/react-hooks';

import { AuthContext } from '../context/auth';
import { BACKEND_GRAPHQL_API_URL } from './constants';

export const AuthRoute = ({ component: Component, ...rest }) => {
  const httpLink = createUploadLink({
    uri: `${BACKEND_GRAPHQL_API_URL}/api/`,
    headers: { authorization: localStorage.getItem('token') },
  });

  const client = new ApolloClient({ link: httpLink, cache: new InMemoryCache() });
  const { user } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={props =>
        user ? (
          <Redirect to="/" />
        ) : (
          <ApolloProvider client={client}>
            <Component {...props} />
          </ApolloProvider>
        )
      }
    />
  );
};

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const httpLink = createUploadLink({
    uri: `${BACKEND_GRAPHQL_API_URL}/api/`,
    headers: { authorization: localStorage.getItem('token') },
  });

  const client = new ApolloClient({ link: httpLink, cache: new InMemoryCache() });

  const { user } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={props =>
        user ? (
          <ApolloProvider client={client}>
            <Component {...props} />
          </ApolloProvider>
        ) : (
          <Redirect to={{ pathname: '/auth/login', state: { from: props.location } }} />
        )
      }
    />
  );
};

export const AdminPrivateRoute = ({ component: Component, ...rest }) => {
  const httpLink = createUploadLink({
    uri: `${BACKEND_GRAPHQL_API_URL}/api/`,
    headers: { authorization: localStorage.getItem('token') },
  });

  const client = new ApolloClient({ link: httpLink, cache: new InMemoryCache() });
  const { user } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={props =>
        user && user.role === 'admin' ? (
          <ApolloProvider client={client}>
            <Component {...props} />
          </ApolloProvider>
        ) : (
          <Redirect to={{ pathname: '/auth/login', state: { from: props.location } }} />
        )
      }
    />
  );
};

export const NavigationMenu = [
  { route: '/', label: 'Home' },
  { route: '/publishers', label: 'Our Publishers' },
  { route: '/category/contents', label: 'All Categories' },
  { route: '/animation/contents', label: 'Animations' },
  { route: '/all/contents', label: 'More' },
];
